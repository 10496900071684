import Link from 'next/link';
import React from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('section')(({ theme }) => ({
  position: 'relative',
  // height: '200px',
  // minHeight: '15vw',
  // maxHeight: '20vw',
  borderStyle: 'solid',
  borderWidth: '1px 0px 0px',
  borderColor: 'rgb(231, 231, 231)',
  // transition:
  //   'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  // padding: '4% 8%',
  // padding: '0% 8% 2%',
  // marginTop: '50px',
  // marginBottom: '50px',

  [theme.breakpoints.down('md')]: {
    // marginBottom: '0px',
  },
}));

const Container = styled('div')(({ theme }) => ({
  padding: '4% 8%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'baseline',
  marginRight: 'auto',
  marginLeft: 'auto',
  // marginBottom: '150px',
  position: 'relative',
  maxWidth: '1800px',
  borderTop: '0px solid rgb(224, 223, 223)',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const HelpSection = styled('div')(({ theme }) => ({
  // position: 'relative',
  display: 'flex',
  width: '50%',
  height: '100%',
  flexWrap: 'wrap',
  // margin: '56px 0px 0px',
  padding: '0px',
  alignContent: 'center',
  // alignItems: 'flex-start',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const HelpTitleContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  textAlign: 'left',
  marginBottom: '0px',
  padding: '0px 0px 15px',
}));

const HelpTitle = styled('h2')(() => ({
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  color: '#070707',
  fontSize: '1.2vw',
  lineHeight: '1.2vw',
  fontWeight: 600,
}));

const HelpDescriptionContainer = styled('div')(() => ({
  textAlign: 'left',
  position: 'relative',
  width: '100%',
  marginBottom: '0px',
  margin: '-11px 0px 0px',
}));

const HelpDescription = styled('span')(() => ({
  boxSizing: 'border-box',
  display: 'inline-block',
  color: '#939393',
  fontSize: '1vw',
  lineHeight: '1.2vw',
  fontWeight: 300,
  whiteSpace: 'break-spaces',
}));

const HelpButtonContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  margin: '14px 0px 0px',
}));

const HelpButtonLink = styled(Link)(() => ({
  width: '100%',
  maxWidth: '170px',
  height: '100%',
  maxHeight: '40px',
  boxSizing: 'border-box',
  padding: '12px 24px',
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  fontSize: '0.8vw',
  color: 'rgb(255, 255, 255)',
  fill: 'rgb(255, 255, 255)',
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  fontWeight: 500,
  borderRadius: '100px',
  backgroundColor: 'rgb(0, 0, 0)',
}));

const InfoSection = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '13.746%',
  flexWrap: 'wrap',
  // margin: '56px 0px',
  marginLeft: 'auto',
  alignContent: 'flex-start',
  alignItems: 'flex-start',

  [theme.breakpoints.down('md')]: {
    width: '50%',
    margin: '20px',
  },
}));

const TitleContainer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  marginBottom: '10px',
  textAlign: 'left',
  padding: '0px 0px 15px',
}));

const Title = styled('h2')(({ theme }) => ({
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  color: '#070707',
  fontSize: '0.7vw',
  lineHeight: '0.9vw',
  fontWeight: 600,
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('md')]: {
    fontSize: '2vw',
    lineHeight: '2vw',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '3.5vw',
    lineHeight: '3.5vw',
  },
}));

const AboutSection = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '11.398%',
  flexWrap: 'wrap',
  // margin: '56px 10px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    width: '50%',
    margin: '20px',
  },
}));

const LicensingSection = styled('div')(({ theme }) => ({
  minHeight: '1px',
  position: 'relative',
  display: 'flex',
  width: '9.561%',
  flexWrap: 'wrap',
  // margin: '56px 0px 0px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    width: '50%',
    margin: '20px',
  },
}));

const List = styled('ul')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  margin: '0px',
  listStyleType: 'none',
  marginTop: '0px',
  marginBottom: '0px',
  padding: '0px',
}));

const ListItem = styled('li')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  margin: '0px',
  padding: '0px',
  marginTop: '0px',
  marginBottom: '0px',
  position: 'relative',
  display: 'flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  fontSize: 'inherit',
  MozBoxPack: 'start',
  justifyContent: 'flex-start',
  textAlign: 'left',
  paddingBottom: 'calc(3px)',
}));

const ListItemLink = styled(Link)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxSizing: 'border-box',
  textDecoration: 'none',
  boxShadow: 'none',
  display: 'flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  width: '100%',
  MozBoxPack: 'start',
  justifyContent: 'flex-start',
  textAlign: 'left',
  fontSize: '0.8vw',
  lineHeight: '0.8vw',
  fontWeight: 400,
  color: '#000',
  [theme.breakpoints.down('md')]: {
    fontSize: '2vw',
    lineHeight: '2vw',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '3.2vw',
    lineHeight: '3.2vw',
  },
}));

const DefaultLabels = {
  helpTitle: 'Precisa de Ajuda?',
  helpDescription:
    'Não encontrou o clipe que procurava, encontrou algum problema?\nMande uma mensagem e deixa a gente te ajudar.',
  helpButtonLabel: 'Fale conosco',
  infoTitle: 'AJUDA E INFORMAÇÕES',
  infoProjects: 'Meus projetos',
  infoAccount: 'Minha conta',
  infoFAQ: 'FAQs',
  infoHelp: 'Precisa de ajuda?',
  aboutTitle: 'SOBRE A BAMBOO',
  aboutWhoWeAre: 'Quem somos',
  aboutJobs: 'Jobs',
  licensingTitle: 'LICENCIAR UMA IMAGEM',
  licensingPlaylist: 'Playlists',
  licensingSearch: 'Pesquisar',
  instagram: 'instagram',
  terms: 'Termos e Condições',
};

export type AppFooterProps = {
  labels?: Partial<typeof DefaultLabels>;
};

const AppFooter = (props: AppFooterProps) => {
  const { labels = DefaultLabels } = props;
  const infoLinks = React.useMemo(
    () => [
      { label: labels?.infoProjects, link: '/dashboard-user/downloads' },
      { label: labels?.infoAccount, link: '/dashboard-user/profile' },
      { label: labels?.infoFAQ, link: '/faq' },
      {
        label: labels?.infoHelp,
        link: process.env.CONTACT_LINK ?? '',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      { label: labels?.terms, link: '/terms' },
    ],
    [labels]
  );

  const aboutLinks = React.useMemo(
    () => [
      {
        label: labels?.aboutWhoWeAre,
        link: '/discover',
      },
      {
        label: labels?.instagram,
        link: process.env.INSTAGRAM_URL ?? '',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
      { label: labels?.aboutJobs, link: '/jobs' },
    ],
    [labels]
  );

  const licensingLinks = React.useMemo(
    () => [
      { label: labels?.licensingPlaylist, link: '/playlists' },
      { label: labels?.licensingSearch, link: '/search' },
    ],
    [labels]
  );

  return (
    <Root>
      <section style={{ height: '200px' }}>
        <Container>
          <HelpSection>
            <HelpTitleContainer>
              <HelpTitle>{labels?.helpTitle}</HelpTitle>
            </HelpTitleContainer>
            <HelpDescriptionContainer>
              <HelpDescription>{labels?.helpDescription}</HelpDescription>
            </HelpDescriptionContainer>
            <HelpButtonContainer>
              <HelpButtonLink
                href={process.env.CONTACT_LINK ?? ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                {labels?.helpButtonLabel}
              </HelpButtonLink>
            </HelpButtonContainer>
          </HelpSection>

          <InfoSection>
            <TitleContainer>
              <Title>{labels?.infoTitle}</Title>
            </TitleContainer>
            <List>
              {infoLinks.map((item, i) => (
                <ListItem key={i}>
                  <ListItemLink
                    href={item.link}
                    rel={item?.rel}
                    target={item?.target}
                  >
                    {item?.label}
                  </ListItemLink>
                </ListItem>
              ))}
            </List>
          </InfoSection>

          <AboutSection>
            <TitleContainer>
              <Title>{labels?.aboutTitle}</Title>
            </TitleContainer>
            <List>
              {aboutLinks.map((item, i) => (
                <ListItem key={i}>
                  <ListItemLink
                    href={item.link}
                    rel={item?.rel}
                    target={item?.target}
                  >
                    {item?.label}
                  </ListItemLink>
                </ListItem>
              ))}
            </List>
          </AboutSection>

          <LicensingSection>
            <TitleContainer>
              <Title>{labels?.licensingTitle}</Title>
            </TitleContainer>
            <List>
              {licensingLinks.map((item, i) => (
                <ListItem key={i}>
                  <ListItemLink href={item.link}>{item?.label}</ListItemLink>
                </ListItem>
              ))}
            </List>
          </LicensingSection>
        </Container>
      </section>
    </Root>
  );
};

export default AppFooter;
